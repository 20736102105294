import Vue from "vue";

import VueRouter from "vue-router";
import VueCookies from "vue-cookies";

import routes from "./routes";
Vue.use(VueCookies);

Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: "active",
  scrollBehavior() {
    window.scrollTo(0, 0);
  }
});

export default router;
